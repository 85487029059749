<template>
	<!-- 确认调整计划 -->
	<el-dialog title="确认节点完成" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="节点名称" prop="nodeName">
				<el-input class="selItemInput" v-model="dataForm.nodeName" :disabled="true" placeholder="节点名称" style="width:300px"></el-input>
			</el-form-item>
			<el-form-item label="实际时间" prop="planTime">
				<el-date-picker class="selItemInput"
								value-format="yyyy-MM-dd"
								v-model="dataForm.actualTime"
								type="date" style="width:300px">
				</el-date-picker>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick @click="visible = false" class="primaryPlainBtn" type="primary" plain>取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "nodeList-confirm",
		data() {
			return {
				//当前页码
				pageIndex: 1,
				//当前记录数
				pageSize: 5,
				nodeinfoList : [],
				visible: false,
				inline: false,
				activeNames: ['1'],
				dataForm: {
					//编号(调用保存的方法时)
					recNo: 0,
					//节点名称
					nodeName: '',
					//编号(调用保存的方法)
					nodeId: '',
					//实际时间
					actualTime: '',
				},
				dataRule: {},
			}
		},
		methods: {
			init(id) {
				// console.log(id,'id')
				this.dataForm.nodeId = id || 0;
				this.visible = true;
				this.$nextTick(() => {
					this.$refs['dataForm'].resetFields()
					if (this.dataForm.nodeId) {
						this.$http({
							url:  this.$store.state.httpUrl +`/business/projectnodeinfo/info/${this.dataForm.nodeId}`,
							method: 'get',
							params: this.$http.adornParams()
						}).then(({data}) => {
							if (data && data.resultCode === 200) {
								this.dataForm.nodeId = data.body.nodeId
								 this.dataForm.nodeName = data.body.nodeName
							}
						})
					}
				})
			},
			// 表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						this.$http({
							url:  this.$store.state.httpUrl +`/business/projectnodeinfo/updateByNodeName/`,
							method: "post",
							data: this.$http.adornData({
								workNo: $common.getItem("workNo"),
								nodeId: this.dataForm.nodeId,
								actualTime:this.dataForm.actualTime
							})
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			},
		}
	}
</script>
<style scoped>

</style>
